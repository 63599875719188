import { IPublicClientApplication } from '@azure/msal-browser';
import { ProvideAccountAnalytics } from '@price-for-profit/analytics';
import { AuthenticationRequired, LoginLayout, LoginPage } from '@price-for-profit/auth';
import { Navigation, buildNavigationRoutes } from '@price-for-profit/drive-account-navigation';
import { DemoResolver, UnauthorizedPage } from '@price-for-profit/drive-app-core';
import { ProvidersWrapper } from 'index';
import { InsightLogoWaterMark, insightLoginLogo, insightLogo, loginClientLogo } from 'logos';
import { AnalyticsResolver } from 'pages/analytics';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from 'routing';

const isProd = process.env.NODE_ENV === 'production';

export function getRoutes(context: drive.ServiceContext, clientApp: IPublicClientApplication, baseUrl: string) {
    return createBrowserRouter(
        [
            {
                element: <ProvidersWrapper context={context} clientApp={clientApp} />,
                children: [
                    {
                        element: <Login />,
                        children: [
                            {
                                path: ROUTES.login,
                                element: (
                                    <LoginPage
                                        tabTitle='Mcc account Login'
                                        insightLoginLogo={insightLoginLogo}
                                        clientLogo={loginClientLogo}
                                        loginRedirectRoute={ROUTES.analytics}
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        element: <Private />,
                        children: [
                            buildNavigationRoutes({
                                clientId: context.appConfig.clientId,
                                redirectRoute: ROUTES.analytics,
                            }),
                            {
                                path: ROUTES.demo,
                                element: <DemoResolver />,
                            },
                            {
                                path: ROUTES.unauthorized,
                                element: <Unauthorized />,
                            },
                        ],
                    },
                    {
                        element: <PrivateAnalytics />,
                        children: [
                            {
                                path: ROUTES.analytics,
                                element: <AnalyticsResolver />,
                            },
                        ],
                    },
                    {
                        path: '*',
                        element: <Navigate to={ROUTES.login} replace />,
                    },
                ],
            },
        ],
        {
            basename: baseUrl,
        }
    );
}

function Unauthorized() {
    return <UnauthorizedPage driveLogo={insightLoginLogo} />;
}

function Login() {
    return (
        <LoginLayout>
            <Outlet />
        </LoginLayout>
    );
}
function PrivateAnalytics() {
    return (
        <AuthenticationRequired>
            <ProvideAccountAnalytics options={{ baseUrl: process.env.PUBLIC_URL }}>
                <Navigation
                    useLocal={!isProd}
                    logos={{
                        clientLogin: loginClientLogo,
                        insightWaterMark: <InsightLogoWaterMark />,
                        insight: insightLogo,
                    }}
                >
                    <Outlet />
                </Navigation>
            </ProvideAccountAnalytics>
        </AuthenticationRequired>
    );
}

function Private() {
    return (
        <AuthenticationRequired>
            <Navigation
                useLocal={!isProd}
                logos={{
                    clientLogin: loginClientLogo,
                    insightWaterMark: <InsightLogoWaterMark />,
                    insight: insightLogo,
                }}
            >
                <Outlet />
            </Navigation>
        </AuthenticationRequired>
    );
}
