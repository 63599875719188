import { IPublicClientApplication } from '@azure/msal-browser';
import '@fontsource/roboto';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import { ProvideInsightAnalytics } from '@price-for-profit/analytics';
import { ProvideAuth } from '@price-for-profit/auth';
import {
    MUI_LICENSE_KEY,
    ProvideErrorBoundary,
    ProvideQueryClient,
    ProvideSnackbar,
} from '@price-for-profit/drive-app-core';
import { getBrowserAuthClients } from '@price-for-profit/micro-services';
import { ProvideTheme } from '@price-for-profit/mui-theme';
import { ProvideService, getAppConfig } from '@price-for-profit/service-provider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { getRoutes } from 'routing';
import { createServiceContext } from 'startup';
import App from './App';
import './index.css';

export interface ProvidersWrapperProps {
    context: drive.ServiceContext;
    clientApp: IPublicClientApplication;
}

export function ProvidersWrapper({ clientApp, context }: ProvidersWrapperProps) {
    return (
        <ProvideTheme>
            <ProvideSnackbar>
                <ProvideErrorBoundary>
                    <ProvideService context={context}>
                        <ProvideAuth clientApp={clientApp}>
                            <ProvideQueryClient>
                                <ProvideInsightAnalytics>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <App />
                                    </LocalizationProvider>
                                </ProvideInsightAnalytics>
                            </ProvideQueryClient>
                        </ProvideAuth>
                    </ProvideService>
                </ProvideErrorBoundary>
            </ProvideSnackbar>
        </ProvideTheme>
    );
}

const run = async () => {
    const baseUrl = process.env.PUBLIC_URL;
    const appConfig = await getAppConfig(baseUrl);
    const { clientApp, httpClient } = await getBrowserAuthClients({
        clientId: appConfig.appId,
        authDomainHost: appConfig.authDomainHost,
        authorityUrl: appConfig.authorityUrl,
        cacheLocation: 'localStorage',
        serviceBaseUrl: appConfig.serviceBaseUrl,
        redirectUrl: baseUrl,
        clientScopes: {
            pbi: appConfig.powerBiAppId,
            auth: appConfig.authorizationAppId,
            event: appConfig.eventAppId,
            das: appConfig.dataAccessAppId,
        },
    });

    LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

    const serviceContext = createServiceContext(appConfig, httpClient);

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <RouterProvider router={getRoutes(serviceContext, clientApp, baseUrl)} />
        </React.StrictMode>
    );
};

run().catch(console.error);
